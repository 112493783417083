import { createContext, useState, useContext, useEffect } from 'react';
import Api from '../services/Axios';

const QuizContext = createContext();

export const QuizProvider = ({ children }) => {
    const [quiz, setQuiz] = useState(null);
    const [quizResult, setQuizResult] = useState(null);
    const [quizStatus, setQuizStatus] = useState(null);
    

    const prepareQuiz = (quiz) => {
        try {
            setQuizStatus('not_started');
            setQuiz(quiz);
        } catch (error) {
            console.log(error);    
        }
        
    }
    const startQuiz = () => {
        setQuizStatus('in_progress');
    }
    const endQuiz = async (quiz, userAnswer) => {
        let { questions, questions_options ,questions_answers } = quiz;
        console.log('cari tahu', userAnswer);
        let finalScore = calculateScore(questions_answers, userAnswer);
        console.log(finalScore);
        let payload = {
            score:(finalScore.finalPercentage),
            score_detail:JSON.stringify(finalScore),
            answers:JSON.stringify(userAnswer),
            quiz_answers:JSON.stringify(questions_answers)
        }

        await Api.post(`/quiz/${quiz.id}/add`, payload)
        .then((response) => {
            if (response.data.code === 'ok'){
                window.alert('Nilai berhasil disubmit!');
                localStorage.removeItem(quiz.id);
                setQuizResult(finalScore)
                setQuizStatus('completed');
                setQuiz((prev) => ({...prev, questions:null, options:null, answers:null}));
            }else if (response.data.code === 'already_taken') {
                window.alert(`Nilai gagal disubmit! Karena sudah mengerjakan sebelumnya!`);
            }else{
                window.alert(`Nilai gagal disubmit! [${response.data.code}] ${response.data.msg}`);
            }
        })
        .catch((err) => {
            if (err?.response?.data?.code){
                window.alert(`Nilai gagal disubmit! Terjadi Kesalahan, [${err?.response?.data?.code}] ${err?.response?.data?.msg}`);
            }else{
                window.alert(`Nilai gagal disubmit! Terjadi Kesalahan!`);
            }
            console.error(err);
        });
    }
    const resetQuiz = () => {
        setQuizStatus(null);
        setQuiz(null);
    }
    useEffect(() => {
        // console.log(quiz);
        // console.log(quizStatus);
    }, [quiz, quizStatus])

    function calculateScore(quizAnswer, userAnswers) {
        let totalScore = 0;  // Initialize total score (actual score the user earned)
        let totalPossibleScore = 0;  // Total possible score (max score for each question)
        let totalScoreBasedOnAllQuestions = 0; // Score based on all questions (to calculate percentage across all questions)
    
        // Loop through each question in the quizAnswer
        Object.entries(quizAnswer).forEach(([questionId, correctAnswers]) => {
            // console.log('Question ID:', questionId);
            // console.log('Correct Answers:', correctAnswers);
    
            // Get the user answer for this question
            const userAnswer = userAnswers[questionId];

            // console.log('Jawaban', userAnswer);
    
            // Check if the question is an essay question (e.g., it has no predefined correct answers or is free-text)
            if (Array.isArray(correctAnswers)) {
                // Handle multiple-choice or checkbox question
                if (userAnswer && Array.isArray(userAnswer)) {
                    // Calculate the number of correct answers selected by the user
                    const correctCount = userAnswer.filter(answer => correctAnswers.includes(answer)).length;
                    
                    // Calculate the percentage score for this question
                    let percentage = 0;
                    if (correctCount !== 0){
                        percentage = (correctCount / correctAnswers.length) * 100;
                    }
    
                    // Add the percentage score to the total score
                    // console.log(correctCount, correctAnswers.length, percentage);
                    totalScore += percentage;
                    totalPossibleScore += 100; // Max possible score for a question with multiple answers
                    totalScoreBasedOnAllQuestions += percentage; // Add the score percentage to total score
                } else {
                    // Handle essay question (assume it is free text)
                    if (userAnswer && typeof userAnswer === 'string') {
                        // For essay question, compare user's answer to the possible correct answers
                        const isEssayCorrect = correctAnswers.some(correctAnswer => 
                            userAnswer.trim().toLowerCase() === correctAnswer.trim().toLowerCase()
                        );
        
                        if (isEssayCorrect) {
                            totalScore += 100; // Full score for essay question if correct
                            totalPossibleScore += 100; // Max possible score for essay question
                            totalScoreBasedOnAllQuestions += 100; // Full score added to total based on all questions
                        } else {
                            // Essay question is incorrect if the answer does not match any correct answer
                            totalPossibleScore += 100; // Still consider essay question as 100 points possible
                            totalScoreBasedOnAllQuestions += 0; // No score added for incorrect answer
                        }
                    } else {
                        // If user didn't provide an answer for essay question, mark it incorrect
                        // console.log('Incorrect answer format for essay question');
                        totalPossibleScore += 100; // Still consider essay question as 100 points possible
                        totalScoreBasedOnAllQuestions += 0; // No score for missing or incorrect essay answer
                    }
                }
            }
        });
    
        // Calculate final percentage score based on possible score
        // console.log(totalScore, totalPossibleScore)
        const finalPercentage = ((totalScore / totalPossibleScore) * 100).toFixed(2);

        // Calculate score based on all questions (this is the average score across all questions)
        const finalScoreBasedOnAllQuestions = Math.round((totalScoreBasedOnAllQuestions / Object.keys(quizAnswer).length));

        // Round to 2 decimal places for totalScore and totalPossibleScore
        const roundedTotalScore = totalScore.toFixed(0);
        const roundedTotalPossibleScore = totalPossibleScore.toFixed(0);

        // console.log('Total Score (Actual Score):', roundedTotalScore);
        // console.log('Total Possible Score:', roundedTotalPossibleScore);
        // console.log('Final Percentage (based on total possible score):', finalPercentage);
        // console.log('Score based on all questions:', finalScoreBasedOnAllQuestions);
    
        return {
            finalPercentage,  // Final percentage based on all questions (rounded)
            totalScore: roundedTotalScore,       // Actual total score the user earned (rounded to 2 decimals)
            totalPossibleScore: roundedTotalPossibleScore, // Total possible score (rounded to 2 decimals)
            finalScoreBasedOnAllQuestions, // Average score across all questions (rounded)
        };
    }

    return (
        <QuizContext.Provider value={{ quiz, setQuiz, quizStatus, prepareQuiz, startQuiz, endQuiz, resetQuiz, quizResult }}>
            {children}
        </QuizContext.Provider>
    );
};

export const useQuizContext = () => useContext(QuizContext);