import React, { useEffect } from 'react';
import { AppProvider } from './context/AppContext';
import { AppRoute } from './AppRoute';

const App = () => {
	return (
		<AppProvider>
			<AppRoute />
		</AppProvider>
	);
};

export default App;
