import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import 'bootstrap-icons/font/bootstrap-icons.css';

if (process.env.NODE_ENV === 'production') {
	// This will prevent React DevTools from showing up in production
	if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
		for (let key in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
			window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = function () {};
		}
	}
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	// <React.StrictMode></React.StrictMode>
	<App />
	
);

// Disable service worker
if ('serviceWorker' in navigator) {
	navigator.serviceWorker.getRegistrations().then((registrations) => {
	  registrations.forEach((registration) => registration.unregister());
	});
  }