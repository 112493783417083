import React, { createContext, useContext, useState } from 'react';
import { UserProvider } from './UserContext';
import { QuizProvider } from './QuizContext';

// Membuat context
const AppContext = createContext();

// Export context
export const useAppContext = () => useContext(AppContext);

// Provider context
export const AppProvider = ({ children }) => {    
    return (
        <AppContext.Provider value={{}}>
            <UserProvider>
                <QuizProvider>
                    {children}
                </QuizProvider>
            </UserProvider>
        </AppContext.Provider>
    );
};
