import axios from 'axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const apiUrls = [
    // 'http://localhost:4000/api',
    // 'https://api-lokal.ieu.link/api',
    'https://api-cbt-trikabta.ieu.link/api'
];

// Create Axios instance
const Api = axios.create({
    baseURL:apiUrls[0],
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true
});

// Function to get the user device ID asynchronously
export const userDeviceId = async () => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    return result.visitorId;  // Return only the unique device ID
};

// Axios request interceptor to add `deviceid` header before each request
Api.interceptors.request.use(
    async (config) => {
        const deviceid = await userDeviceId();
        config.headers['DeviceId'] = deviceid;  // Set the device ID header
        if (!config.apiIndex) { 
            config.apiIndex = 0;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Axios response interceptor to handle errors globally
Api.interceptors.response.use(
    (response) => response, // Return response data as-is
    async (error) => {
        let apiIndex = error.config.apiIndex;
        console.log('errorr');
        // Handle error response and switch to the next API in case of failure
        if (apiIndex < apiUrls.length - 1) {
            
            apiIndex ++;
            error.config.baseURL = apiUrls[apiIndex];
            error.config.apiIndex = apiIndex;
            // console.log('second try', apiIndex, apiUrls[apiIndex]);

            try {
                const retryResponse = await Api.request(error.config);
                return retryResponse;
            } catch (retryError) {
                return Promise.reject(retryError);
            }
        }else{
            // Handle token expiration or other errors globally
            if (error.response && error.response.status === 401) {
                console.log('keluar', error.response.data.code);
                if (error.response.data.code === 'invalid_token' || error.response.data.code === 'device_not_match') {
                    console.log('keluar!');
                    error.config.url = '/auth/logout';
                    error.config.method = 'post';
                    await Api.request(error.config)
                    .then((response) => {
                        if (response.data.code === 'ok'){
                            window.location.reload();
                        }
                    })
                    .catch((err) => {
                        console.log(err?.response?.data?.msg, err);
                    });
                }
            }

            apiIndex = 0;
            return Promise.reject(error);
        }
    }
);

export default Api;  // Export the configured Axios instance
