import React, { createContext, useState, useContext, useEffect } from 'react';
import Api from '../services/Axios';
import { Navigate } from 'react-router-dom';

// Create a context
const UserContext = createContext();

// Custom hook to access user context
export const useUserContext = () => useContext(UserContext);

// UserProvider component to wrap the app and provide the user context
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [attempt, setAttempt] = useState(true);

    // Login pertama kali
    useEffect(() => {
        Api.get('/participant/me')
        .then((response) => {
            if (response.data.code === 'ok'){
                const { account } = response.data;

                setUser(account);
            }
        })
        .catch((err) => {
   
        })
    }, []);

    const login = async (username, password) => {
        await Api.post('/auth/login', { username, password })
        .then((response) => {
            if (response.data.code === 'ok'){
                const { account } = response.data;
                setUser(account);

                const { msg } = response.data ?? null;
                setError(null);
            }else{
                setError('Gagal Masuk');
                
            }
        })
        .catch((err) => {
            setUser(null);
            window.alert(err?.response?.data?.msg)
            setError(err?.response?.data?.msg);
            console.error(err);
        });
    };

    const logout = async () => {
        await Api.post('/auth/logout')
        .then((response) => {
            if (response.data.code === 'ok'){
                const { msg } = response.data ?? null;

                setUser(null);
                setError(null);
                window.location.reload();
            }else{
                setError('Gagal Keluar');
            }
        })
        .catch((err) => {
            setError(err?.response?.data?.msg);
            console.log(err);
        });
    };

    useEffect(() => {
        if (error){
            setError(null);
        }
    }, [error]);

    return (
        <UserContext.Provider value={{ user, login, logout }}>
        {children}
        </UserContext.Provider>
    );
};
