import React from "react";
import Navbar from "../layout/components/Navbar";

const PageLayout = ({ children }) => {
    return (
		<div className="min-h-svh flex flex-col items-center justify-start md:justify-center py-4">
			<div className="container w-full max-w-sm mx-auto md:bg-base-200 md:rounded-2xl overflow-hidden md:shadow-xl">
				<div className="flex flex-col gap-4">
					<Navbar></Navbar>

					<main className="grid gap-4 p-2">
						{children}
					</main>
				</div>
			</div>
		</div>
	)
};

export default PageLayout;