import React from 'react';
import { useUserContext } from '../../context/UserContext';

const Navbar = () => {
    const { user, logout } = useUserContext();

    const handleLogout = async () => {
        await logout();
    };

    const handleHardRefresh = () => {
        window.location.reload();
    }

    const ViewComponent = () => (
        <>
        <div className="h-14 sm:hidden"></div>
        <nav className="navbar gap-4 fixed sm:static bg-base-100 sm:bg-transparent top-0 left-0 z-50">
            <div className="navbar-start flex-grow">
                <h1 className="btn btn-ghost rounded-2xl text-xl">
                    <img src="/192.png" alt="" srcset="" className='h-full'/>
                    CBT Trikabta
                </h1>
            </div>
            <div className="navbar-end w-auto">
                <div className="dropdown dropdown-end">
                    <div tabIndex="0" role="button" className="btn btn-ghost rounded-2xl">
                        <div className="indicator">
                            <i className="bi bi-three-dots-vertical text-xl"></i>
                        </div>
                    </div>
                    <ul tabIndex="0" className="dropdown-content menu bg-base-300 rounded-2xl z-[1] w-40 p-2 mt-2 shadow-2xl">
                        {user && <li><a onClick={() => {handleLogout()}}>Keluar</a></li> }
                        <li><a onClick={() => { handleHardRefresh() }}>Hard Refresh</a></li>
                    </ul>
                </div>
            </div>
        </nav>
        </>
    );
    
    return <ViewComponent />;
};

export default Navbar;
