import React, { useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { useUserContext } from './context/UserContext';
import PageLayout from './layout/PageLayout';

const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const QuizPage = lazy(() => import('./pages/QuizPage'));
const AdminResponsePage = lazy(() => import('./pages/AdminResponsePage'));
const AdminSyncPage = lazy(() => import('./pages/AdminSyncPage'));
const AdminCredentialPage = lazy(() => import('./pages/AdminCredentialPage'));

const ProtectedLayout = () => {
    const { user } = useUserContext();

    if (!user) {
        return <Navigate to="/login" replace />;
    }

    return <Outlet />;
};

export const AppRoute = () => {
    const { user } = useUserContext();

    return (
        <Router>
            <Suspense fallback={<LoadingView />}>
                <Routes>
                    <Route path="/siap86" element={<AdminCredentialPage />} />
                    <Route path="/siap86/response" element={<AdminResponsePage />} />
                    <Route path="/siap86/sync" element={<AdminSyncPage />} />
                    <Route element={<PageLayout><Outlet /></PageLayout>}>
                        <Route element={<ProtectedLayout />}>
                            <Route path="/" element={<QuizPage></QuizPage>} />
                        </Route>
                        <Route path="/login" element={!user ? <LoginPage /> : <Navigate to='/' />} />
                        <Route path="*" element={<NotFoundPage />} />
                    </Route>
                </Routes>
            </Suspense>
        </Router>
    );
};

const LoadingView = () => (
    <div className="z-50 fixed top-0 left-0 min-h-svh min-w-full bg-base-100 flex flex-col justify-center items-center">
        <span className="loading loading-spinner loading-lg"></span>
    </div>
);